@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');;
body { 
    font-family: 'Playfair Display', serif;
    position: relative;
}
@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli.woff2') format('woff2'),
        url('../fonts/Muli.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Light.woff2') format('woff2'),
        url('../fonts/Muli-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


a.active,
a:focus,
button:focus,
button.active {
    outline: none;
}
a {
    text-decoration: none;
}
a:focus,
a:hover,
a:active {
    outline: 0;
    box-shadow: none;
}
a:focus,
a:hover {
    text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 { 
    margin-bottom: 0;
    font-family: 'Playfair Display', serif; 
}
a {
    text-decoration: none;
    transition: all .5s ease-in-out;
}
a:hover {
    text-decoration: none;
}
ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}
ul li {
    list-style: none;
}
p {
    margin: 0;
}
input:focus,
select:focus,
textarea:focus {
    outline: none;
}
img {
    border-style: none;
    display: inline-block;
    max-width: 100%;
    height: auto;
}

/********  GENERIC  *******/
.body-normal {
    text-align: left;
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.3em;
    font-family: 'Muli';
    display: block;
    width: 100%;
}

p.body-normal {    
    margin-bottom: 10px;
}

/*********** HEART ******/

header {
    /* position: fixed; */
    background-color: white;
    width: 100%;
    z-index: 1000;
}

/******* HEADER BOTTOM *****/
.header-bottom {
    /* padding: 14px 0; */
    border-bottom: 1px solid #9b9b9b;
}
.navigation{
    max-width: 1760px;
    width: 100%;
    margin: 0 auto;
}

.navigation h1 {
    font-size: 14px;
    color: #012D78;
    display: inline-block;
    font-size: 25px;
}
.navigation .navbar {
    padding: 0;
}
.navigation .navbar-nav .nav-item{
    padding: 0 45px;
}
.navigation .navbar-nav .nav-item:first-child{
    padding-left: 0;
}
.navigation .navbar-nav .nav-item:last-child{
    padding-right: 0;
}
.navigation .navbar-nav .nav-item .nav-link{
    color: #444444;
    font-size: 18px;
    font-weight: bold;
    padding: 0;
}
.navigation .navbar-nav .nav-item .nav-link.active,
.navigation .navbar-nav .nav-item .nav-link:hover{
    color: #95b595;
}
.header-social ul{
    display: flex;
    align-items: center;
}
.header-social ul li{
    padding: 0 15px;
    position: relative;
}
.header-social ul li:after{
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    width: 1px;
    height: 7px;
    background-color: #e8e8e8;
}
.header-social ul li:first-child:after{
    display: none;
}
.header-social ul li:first-child{
    padding-left: 0;
}
.header-social ul li:last-child{
    padding-right: 0;
}
/******** HERO SEC *******/
.hero-sec {
    /* background: url(../images/photo-1564951434112-64d74cc2a2d7.jpeg) no-repeat center; */
    background-size: cover;
    
    /* height: 850px; */
    display: flex;
    align-items: center;
    justify-content: center;
    
    margin-top: 50px;
}

.hero-sec .text-box {
    /* background-color: #00000040; */
    padding: 11px;
    border-radius: 29px;
}

.hero-sec .text-box h1 {
    font-weight: bold;
    font-size: 50px;
    color: #000;
    text-align: center;
    /* text-shadow: 0px 2px 9.75px rgb(0 0 0); */
}

.hero-sec .text-box p{
    text-align: center;
    font-family: 'Muli';
    font-weight: bold;
    font-size: 20px;
    color: #000;
    /* text-shadow: 0px 2px 5.6px rgb(0 0 0); */
    text-transform: uppercase;
}
/******  How i work *******/

.how-i-work-sec {
    position: relative;
    background-color: white;
    padding: 75px 0 75px;
    text-align: center;
}

.how-i-work-sec .text-box {
    text-align: left;
}



/******** SERVICES SEC *******/
.services-sec {
    position: relative;
    background-color: #DEEADE;
    padding: 75px 0 75px;
    text-align: center;
}
.title{
    text-align: center;
}
.title h3{
    padding: 20px 0; 
    font-weight: bold;
    font-size: 45px;
    color: #444444;
}
.title p{
    margin: 0 auto;
    max-width: 785px;
    font-family: 'Muli';
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: #787878;
}
.services-inner {
    padding: 30px 0px 0;
}
.services-box{
    padding: 30px 10px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}
.services-box{
    background-color: #fff;
    border: solid 1px rgba(0, 0, 0, 0.1);
    /* box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2); */
}
.services-box:hover{
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease-in-out;
}
.services-box:hover .content-box h4{
    color: #012D78;
    transition: all 0.5s ease-in-out;
}
.services-box .content-box{
    padding: 27px 0 0 0;
}
.services-box .content-box h4{ 
    font-family: 'Muli';
    font-weight: bold;
    font-size: 16px; 
    color: #444444;
    transition: all 0.5s ease-in-out;
    margin-bottom: 22px;
}
.services-box .content-box p{
    margin: 0 auto;
    max-width: 240px;
    font-family: 'Muli';
    font-weight: 300;
    font-size: 16px;
    color: #787878;
}
.services-box .image-holder {
    width: 50px;
    justify-content: center;
    align-self: center;
    margin: 0px auto;
}
/********** FAMILY CONSULTING SEC *******/
.about-us {
    position: relative;
    background-color: #DEEADE;
    padding: 75px 0 75px;
}
.shadow{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.about-us .image-holder {
    text-align: right;
    position: relative;
    z-index: 10;
    padding: 10px;
    /* margin-top: 71px; */
}
.about-us .text-box{
    max-width: 556px;
    width: 100%;
    margin: 0 auto;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
}
.about-us .text-box h3 {
    text-align: center;
    color: #000000;
    font-size: 45px;
    font-weight: bold;
    margin-bottom: 30px;
}
.about-us .text-box.qualifcations {
    text-align: left;
    color: #000000;
    align-items: self-start;
}

.about-us .text-box.qualifcations h5 {
    text-align: left;
    color: #000000;
    font-size: 25px;
    font-weight: bold;
    margin: 10px 0px 0px 0px;
    align-self: baseline;
}

.about-us .text-box strong{
    color: #000000;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: bold;
    line-height: 28px;
    font-family: 'Muli';
    display: block;
    width: 100%;
}
/* .about-us .text-box p, .about-us .text-box li {
    text-align: left;
    color: #000000;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5em;
    font-family: 'Muli';
    display: block;
    width: 100%;
} */

.about-us .text-box p {
    margin: 0 0 16px 0;
}

.about-us .text-box h4{
    color: #000000;
    font-size: 16px;
    font-weight: bold; 
    font-family: 'Muli';
    text-transform: uppercase;
    margin: 15px 0;
}
.about-us .text-box h3 span {
    font-weight: 300;
    font-size: 20px;
    display: block;
}
/********** ARTICLES SEC ********/
.articles-sec{
    padding: 104px 0;
}
.articles-inner{
    padding: 70px 0 0 0;
}
.articles-box {
    margin: 15px 0;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}
.articles-box .image-holder img{
    width: 100%;
}
.articles-box .text-box{
    text-align: center;
    padding: 60px 15px;
}
.articles-box .text-box span{
    color: #012D78;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Muli';
    text-transform: uppercase;
    display: block;
}
.articles-box .text-box h3{
    color: #444444;
    font-size: 25px;
    font-weight: bold; 
    line-height: 28px;
    position: relative;
    padding-bottom: 27px; 
    max-width: 234px;
    width: 100%;
    margin: 18px auto 23px;
}
.articles-box .text-box h3:after{
    position: absolute;
    content: "";
    left: 50%;
    transform: translate(-50%,0);
    background-color: #d2d2d2;
    width: 30px;
    height: 1px;
    bottom: 0;
}
.articles-box .text-box p{
    color: #787878;
    font-size: 18px;
    font-family: 'Muli';
    line-height: 28px;
    font-weight: 300;
    max-width: 295px;
    width: 100%;
    margin: 0 auto 53px;
}
.articles-box .text-box a{
    color: #000;
    font-size: 18px;
    font-family: 'Muli';
    font-weight: 300;
    background-color: #012D78;
    max-width: 161px;
    height: 51px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.articles-box .text-box a:hover{
    background-color: #d9be93;
}
/********* TESTIMONIAL SEC *******/
.testimonial-sec{
    background-color: #c3ab84;
    padding: 87px 0;
}
.testimonial-sec .text-box h3{
    line-height: 1.14;
    font-size: 35px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: #000;
    max-width: 966px;
    width: 100%;
    margin: 0 auto 28px;
}
.testimonial-sec .text-box span{
    color: #000;
    font-size: 18px;
    font-weight: 300;
    font-family: 'Muli';
    display: block;
    text-align: center;
}
.carousel-indicators {
    margin-bottom: 30px;
    position: static;
}
.carousel-indicators li{
    opacity: 1;
    width: 11px;
    margin: 0 5px;
    background-color: #e7d9c0;
    height: 10px;
    border: 3px solid transparent;
    transition: all 0.5s ease-in-out;
}
.carousel-indicators .active{
    background-color: #fff;
    border-color: #e7d9c0;
    transition: all 0.5s ease-in-out;
}
/********** FAQ SEC ******/
.faq-sec{
    padding: 100px 0;
}
.faq-inner{
    padding-top: 87px;
}
.card {
    background-color: transparent;
    border: none;
    border-radius: 0; 
    transition: all 0.5s ease-in-out;
    position: relative;
}
.card:hover{
    box-shadow: 0px 2px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    transition: all 0.5s ease-in-out;
}
.card:hover .card-header .btn-link{
    color: #012D78;
    transition: all 0.5s ease-in-out;
}
.card .card-header{
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 0; 
    transition: all 0.5s ease-in-out;
}
.card .card-header .btn-link{
    padding: 22px 28px;
    border: none;
    color: #444444;
    font-size: 25px;
    background-color: transparent;
    font-weight: bold;
    display: block;
    width: 100%;
    text-decoration: none; 
    text-align: left;
    transition: all 0.5s ease-in-out;
}
.card .card-header .btn-link[aria-expanded="true"]{
    box-shadow: 0px 2px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    color: #012D78; 
    transition: all 0.5s ease-in-out;
}
.card .collapse.show {
    box-shadow: 0px 8px 9px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
}
.card-body{
    padding: 0 30px 30px;
}
.card-body p{
    color: #787878;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
}
.mySwiper2 .image-holder{
    position: relative;
}
.mySwiper2 .image-holder img{
    width: 100%;
}
.mySwiper2 .image-holder a{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.mySwiper {
    margin-top: 10px;
}
.mySwiper .image-holder{
    cursor: pointer;
}
.mySwiper .image-holder img{
    width: 100%;
}
/********* PRICE SEC *****/
.price-sec{
    background-color: #DEEADE;
    padding: 90px 0;
    position: relative;
}
.price-box{
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.price-box-first:after{
    right: auto;
    left: 0;
}
.price-box:after{
    position: absolute;
    content: "";
    right: 0;
    top: 50%;
    transform: translate(0,-50%);
    background-color: black;
    width: 1px;
    height: 134px;
}
.price-box h3{
    color: #000000;
    line-height: 40px;
    font-size: 35px;
    font-weight: bold;
    /* max-width: 142px; */
    margin: 0 auto;
    width: 100%;
}
.price-box img{
    margin: 26px 0;
}
.price-box h4{
    font-size: 40px;
    font-weight: 300;
    font-family: 'Muli';
    color: #000;
}
.price-box h5{
    padding-bottom: 4px;
}
.price-box span{
    font-size: 12px;
    font-weight: 300;
    font-family: 'Muli';
    display: block;
    color: #000;
    text-transform: uppercase;
}
.price-box.price-box-mid {
    box-shadow: 0px 2px 20px 0 rgb(0 0 0 / 10%);
    background-color: #ffffff;
    padding: 43px 30px 60px;
}
.price-box.price-box-mid:after{
    display: none;
}
.price-box ul {
    padding-bottom: 40px;
    width: 100%;
}
.price-box ul li {
    color: #787878;
    font-size: 16px;
    border-bottom: 1px solid #ebebeb;
    font-weight: 400;
    padding: 10px 0;
}
.price-box ul li:last-child{
    border-bottom: none;
}
.price-box a{
    background-color: #012D78;
    width: 143px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 300;
    font-family: 'Muli';
    color: #000;
    margin: 0 auto;
}
.price-box a:hover{
    background-color: #d9be93;
}

.circle {
    background-color: black;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 10px;
}

/********* NEWSLETTER SEC *******/
.contact-sec {
    padding: 75px 0 75px;
}
.contact-sec .title img{
    filter: grayscale(1);
}
.contact-sec .title h3{
    color: #525252;
}
.contact-sec .title p{
    max-width: 563px;
    width: 100%;
}

.contact-sec p {
    max-width: 556px;
    width: 100%;
    margin: 0 auto;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #787878;
    font-weight: 300;
}

.contact-sec h5 {
    text-align: center;
    color: #525252;
    font-size: 32px;
    font-weight: bold;
    margin: 24px;
}
.newsletter-inner {
    max-width: 570px;
    width: 100%;
    margin: 0 auto;
    padding: 12px 0 0 0;
}
.newsletter-inner .form-group{
    margin-bottom: 50px;
    position: relative;
}
.newsletter-inner .form-group span.arrow-icon{
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    right: 0;
}
.newsletter-inner .form-group select.form-control{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.newsletter-inner .form-group .form-control{
    border: none;
    box-shadow: none;
    border-radius: 0; 
    border-bottom: 1px solid #d2d2d2;
    padding: 0;
    color: #787878;
    font-size: 16px;
    font-weight: 300;
    transition: all 0.5s ease-in-out;
    font-family: 'Muli';
}
.newsletter-inner .form-group .form-control:hover{
    color: #444444;
    border-bottom-color: #012D78;
    transition: all 0.5s ease-in-out;
}
.newsletter-inner .form-group .form-control:hover::placeholder{
    color: #444444;
    transition: all 0.5s ease-in-out;
}
.newsletter-inner .form-group textarea.form-control{
    resize: none;
}
.newsletter-inner .form-group .btn-default{
    box-shadow: 0px 5px 18px 2px #888d8c1c;
    background-color: #DEEADE;
    width: 182px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #000000;
    font-size: 18px;
    font-family: 'Muli';
    font-weight: 300;
    border-radius: 0;
    margin: 0 auto;
}
.newsletter-inner .form-group .btn-default:hover{
    background-color: #d9be93;
}
/******* FOOTER *******/
footer{
    background-color: #c3ab84;
    padding: 10px 0 10px;
}
.copyright{
    text-align: center;
}
.copyright p{
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    font-family: 'Muli';
    text-transform: uppercase;
}