
@media(max-width:1600px){
    .header-detail ul li{
        padding: 0 10px;
    }
    .header-login li {
        padding: 0 11px;
    }
    .navigation .navbar-nav .nav-item {
        padding: 0 30px;
    }
    .header-social ul li {
        padding: 0 10px;
    }
    /* .hero-sec {
        height: 570px;
    } */
    .about-us .text-box h3 { 
        margin-bottom: 30px;
    }
    .about-us .text-box p,
    .about-us .text-box strong{
        margin: 0 0 10px 0;
    }
    .card .card-header .btn-link{
        font-size: 22px;
    }
}
@media(max-width:1199px){
    .header-detail ul li a {
        font-size: 14px;
    }
    .header-login li a {
        font-size: 14px;
    }
    .header-detail ul li a {
        font-size: 12px; 
    }
    .header-appointment a {
        font-size: 14px;
        width: 180px;
        height: 50px;
    } 
    .navigation .navbar-nav .nav-item {
        padding: 0 15px;
    }
    .services-box .content-box p{
        font-size: 14px;
    }
    .about-us .text-box h3 {
        margin-bottom: 15px;
        font-size: 34px;
    }
    .about-us .text-box strong{
        font-size: 16px;
        margin-bottom: 5px;
    }
    .about-us .text-box p{
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 5px;
    }
    .articles-box .text-box {
        padding: 30px 15px;
    }
    .articles-box .text-box h3 {
        font-size: 22px;
        line-height: 28px;
        padding-bottom: 15px;
        margin: 15px auto;
    }
    .articles-box .text-box p {
        font-size: 16px;
        margin: 0 auto 30px;
    }
    .card .card-header .btn-link{
        padding: 15px;
        font-size: 20px;
    }
    .card-body {
        padding: 0 20px 20px;
    }
    .card-body p {
        font-size: 16px;
        line-height: 24px;
    }
    .price-box.price-box-mid {
        padding: 40px 15px;
    }
    .price-box h3{
        font-size: 28px;
    }
}
@media(max-width:991px) {
    .header-top-inner .media { 
        flex-wrap: wrap;
    }
    .header-login{
        order: 1;
    }
    .header-detail{
        order: 3;
        width: 100%;
        padding: 10px 0;
    }
    .header-appointment{
        order: 2;
    }
    .header-appointment a {
        height: 40px;
    }
    .header-detail ul{
        justify-content: center;
    }
    .navbar-light .navbar-brand{
        padding: 0;
        margin: 0;
    }
    .navbar-light .navbar-toggler{
        border: none;
        border-radius: 0;
        padding: 0;
    }
    .navbar-collapse{
        position: absolute;
        top: 51px;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 10;
    }
    .navigation .navbar-nav .nav-item {
        padding: 0;
    }
    .navigation .navbar-nav .nav-item .nav-link {
        padding: 10px 15px;
    }
    .header-social ul {
        justify-content: center;
        padding: 10px 0px 20px;
    }
    .header-social ul li {
        padding: 0 20px;
    }
    /* .hero-sec {
        height: 450px;
    } */
    /* .hero-sec .text-box h1{
        font-size: 70px;
    } */
    .about-us {
        padding: 50px 0px 50px;
    }
    .about-us .image-holder {
        text-align: center;
        padding: 30px;
    }
    .articles-box .text-box {
        padding: 20px 10px;
    }
    .articles-box .text-box h3 {
        font-size: 18px;
        line-height: 24px;
    }
    .articles-box .text-box p {
        font-size: 14px;
        margin: 0 auto 24px;
        line-height: 20px;
    }
    .testimonial-sec .text-box h3{
        font-size: 28px;
    }
    .carousel-indicators li{
        width: 10px;
    }
    .card .card-header .btn-link {
        padding: 15px;
        font-size: 16px;
    }
    .card-body p {
        font-size: 14px;
        line-height: 22px;
    }
    .price-box-first:after{
        display: none;
    }
    
    .price-box:after{
        display: none;
    }
}
@media(max-width:767px) {
    .header-detail ul {
        flex-wrap: wrap;
    }
    .header-detail ul li {
        padding: 5px 10px;
    }
    .header-detail{
        display: none;
    }
    .header-appointment a {
        height: 40px;
    }
    /* .hero-sec {
        height: 350px;
    } */
    .hero-sec .text-box h1 {
        /* font-size: 60px; */
    }
    .services-sec { 
        padding: 50px 0;
    }
    .title h3 {
        padding: 15px 0;
        font-size: 34px;
    }
    .title h5 {
        font-size: 24px;
        margin: 15px 0;
    }
    .title p{
        font-size: 14px;
        line-height: 20px;
    }
    .services-inner {
        padding: 30px 0px 0;
    }
    .services-box {
        padding: 15px 0;
    }
    .articles-sec {
        padding: 50px 0 35px;
    }
    .articles-inner {
        padding: 15px 0 0 0;
    }
    .articles-box .text-box {
        padding: 20px;
    }
    .articles-box .text-box h3 {
        font-size: 26px;
        line-height: 36px;
    }
    .articles-box .text-box p {
        font-size: 18px;
        line-height: 26px;
    }
    .testimonial-sec {
        padding: 50px 0;
    }
    .testimonial-sec .text-box h3 {
        font-size: 20px;
        margin: 0 auto 15px;
        line-height: 28px;
    }
    .carousel-indicators {
        margin-bottom: 20px;
    }
    .faq-sec {
        padding: 50px 0;
    }
    .faq-inner {
        padding-top: 30px;
    }
    .card .card-header .btn-link {
        padding: 20px;
        font-size: 18px;
        line-height: 24px;
    }
    .card-body p {
        font-size: 16px;
        line-height: 24px;
    }
    .faq-right{
        margin-top: 20px;
    }
    .price-sec {
        padding: 50px 0;
        position: relative;
    }
    .price-box{
        margin: 15px 0;
    }
    .price-box.price-box-mid {
        padding: 20px 15px;
    }
    .contact-sec {
        padding: 50px 0;
    }
    .newsletter-inner {
        padding: 30px 0 0 0;
    }
}
@media(max-width:577px) {
    .navbar-collapse{
        top: 47px;
    }
    .header-login li {
        padding: 0 7px;
    }
    .header-appointment a {
        height: 35px;
        font-size: 12px;
        width: 150px;
    }
    .navbar-light .navbar-brand img{
        max-width: 170px;
        width: 100%;
    }
    .hero-sec .text-box h1 {
        font-size: 46px;
    }
    .hero-sec .text-box p{
        font-size: 14px;
    }
    .hero-sec {
        margin-top: 30px;
    }
    .about-us .text-box h3 {
        font-size: 24px;
    }
    .about-us .text-box strong,
    .about-us .text-box p{
        font-size: 14px;
        line-height: 22px;
    }
    .carousel-indicators li {
        width: 9px;
        height: 9px;
        border-width: 2px;
    }
    .title h3 {
        font-size: 24px;
    }
    .title h5 {
        font-size: 18px;
    }
    .card .card-header .btn-link { 
        font-size: 16px;
        line-height: 26px;
    }
    .card .card-header .btn-link {
        padding: 10px 20px;
    }
    .price-box {
        height: auto;
    }
    .copyright p{
        padding: 20px 0 0 0;
    }
}
@media(max-width:374px) {
    .header-login li a {
        font-size: 12px;
    }
    .header-appointment a {
        font-size: 11px;
        width: 135px;
    }
    .carousel-indicators li {
        width: 8px;
        height: 8px;
        border-width: 2px;
    }
    .card .card-header .btn-link{
        font-size: 14px;
    }
}